import { TOGGLE_CUSTOMER_TYPE } from '../actions/customerType.actions';

const initialState = localStorage.getItem('customerType') || 'Residential';

export default function customerType(state = initialState, { type, data }) {
  switch (type) {
    case TOGGLE_CUSTOMER_TYPE:
      return data;
    default:
      return state;
  }
}
