import React, { lazy } from 'react';

import RenderOnViewportEntry from './RenderOnViewportEntry';

import contentData from '../../content/pages/home.json';

const Slide = lazy(() => import('./Slide'));

export default function HomePage(props) {
  const { slides } = contentData;
  const { config, button } = props;

  const slideProps = {
    button,
    baseUrl: config.base_url,
    missingPostalCodes: config.missing_postal_codes,
  };

  return (
    <div className="home">
      {slides.map((slide, index) => (
        <RenderOnViewportEntry threshold={0.1} className="slide" key={index}>
          <Slide index={index} slide={slide} {...slideProps} />
        </RenderOnViewportEntry>
      ))}
    </div>
  );
}
