import React, { useRef, Suspense } from 'react';

import { Loader } from 'semantic-ui-react';

import useFirstViewportEntry from './useFirstViewportEntry';

export default function RenderOnViewportEntry({
  children,
  root = null,
  threshold = 0,
  rootMargin = '0px 0px 0px 0px',
  ...wrapperDivProps
}) {
  const ref = useRef();
  const entered = useFirstViewportEntry(ref, { threshold, root, rootMargin });

  const fallback = <Loader active style={{ color: 'var(--primaryColor)' }} />;

  return (
    <div {...wrapperDivProps} ref={ref}>
      {entered && <Suspense fallback={fallback}>{children}</Suspense>}
    </div>
  );
}
