import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (pathname.includes('/legal/')) {
      return;
    }

    window.scrollTo({ top: 0 });
  }, [pathname]);
};

export default ScrollToTop;
