import React, { useEffect } from 'react';

const WithZammadChat = (Component) => {
  const NewComponent = (props) => {
    // Inject Zammad scripts on component mount
    useEffect(() => {
      injectZammadScript(props.config.zammad_chat_id);
    }, []);

    return <Component {...props} />;
  };

  return NewComponent;
};

export default WithZammadChat;

const injectZammadScript = (zammad_chat_id) => {
  // Add JQuery script
  const jQueryScript = document.createElement('script');
  jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';

  // Add Zammad library script
  const zammadLibraryScript = document.createElement('script');
  zammadLibraryScript.src = 'https://tickets.lilaconnect.co.uk/assets/chat/chat.min.js';

  // Add Zammad Chat script
  const zammadChatScript = document.createElement('script');

  zammadChatScript.innerText = `$(function() {
    new ZammadChat({
      background: 'var(--primaryColor)',
      fontSize: '12px',

      chatId: ${zammad_chat_id},
      flat: true
    });
  });`;

  // Append scripts to the DOM
  document.head.appendChild(jQueryScript);
  // Append Zammad library script once JQuery is loaded
  jQueryScript.onload = () => {
    document.body.appendChild(zammadLibraryScript);
  };
  // Append Zammad chat script once Zammad library is loaded
  zammadLibraryScript.onload = () => {
    document.body.appendChild(zammadChatScript);
  };
};
