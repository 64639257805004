import React, { lazy, Suspense } from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { componentLoader } from 'portal-components/utils';
import LoadingSpinner from 'portal-components/LoadingSpinner';

import faqContent from '../content/faqs.json';

import HomePage from '../pages/HomePage';
import withZammadChat from '../components/withZammadChat';

// Portal components
const FaqPage = lazy(() => componentLoader(() => import('portal-components/FaqPage'), 3));
const NewsPage = lazy(() => componentLoader(() => import('portal-components/NewsPage'), 3));
const ProfilePage = lazy(() => componentLoader(() => import('portal-components/ProfilePage'), 3));
const NewsPostPage = lazy(() => componentLoader(() => import('portal-components/NewsPostPage'), 3));
const NotFoundPage = lazy(() => componentLoader(() => import('portal-components/NotFoundPage'), 3));

const ContactUsPage = lazy(() =>
  componentLoader(() => import('portal-components/ContactUsPage'), 3),
);
const ContactUsPageWithZammad = withZammadChat(ContactUsPage);

const UnauthorizedPage = lazy(() =>
  componentLoader(() => import('portal-components/UnauthorizedPage'), 3),
);
const PasswordResetPage = lazy(() =>
  componentLoader(() => import('portal-components/PasswordResetPage'), 3),
);
const CustomerServicesPage = lazy(() =>
  componentLoader(() => import('portal-components/CustomerServicesPage'), 3),
);
const RequestPasswordResetPage = lazy(() =>
  componentLoader(() => import('portal-components/RequestPasswordResetPage'), 3),
);
const AccountVerificationPage = lazy(() =>
  componentLoader(() => import('portal-components/AccountVerificationPage'), 3),
);
const AccountVerificationPageNew = lazy(() =>
  componentLoader(() => import('portal-components/AccountVerificationPageNew'), 3),
);

const LegalPage = lazy(() => componentLoader(() => import('../pages/LegalPage'), 3));
const BusinessPage = lazy(() => componentLoader(() => import('../pages/BusinessPage'), 3));
const FullFibrePage = lazy(() => componentLoader(() => import('../pages/FullFibrePage'), 3));
const ResidentialPage = lazy(() => componentLoader(() => import('../pages/ResidentialPage'), 3));
const AboutUsPage = lazy(() => componentLoader(() => import('../pages/AboutUsPage'), 3));
const ServiceStatusPage = lazy(() =>
  componentLoader(() => import('../pages/ServiceStatusPage'), 3),
);
const ReferralPage = lazy(() => componentLoader(() => import('../pages/ReferralPage'), 3));
const CoveragePage = lazy(() => componentLoader(() => import('../pages/CoveragePage'), 3));
const OffersPage = lazy(() => componentLoader(() => import('../pages/OffersPage'), 3));
const ServiceCatalogPage = lazy(() =>
  componentLoader(() => import('../pages/ServiceCatalogPage'), 3),
);
const PersonalAreaPage = lazy(() => componentLoader(() => import('../pages/PersonalAreaPage'), 3));
const LoginPage = lazy(() => componentLoader(() => import('../pages/LoginPage'), 3));
const MyPaymentsPage = lazy(() => componentLoader(() => import('../pages/MyPaymentsPage'), 3));
const SpeedTestPage = lazy(() => componentLoader(() => import('../pages/SpeedTestPage'), 3));
const AppointmentPage = lazy(() => componentLoader(() => import('../pages/AppointmentPage'), 3));
const FinaliseCampaignPage = lazy(() =>
  componentLoader(() => import('../pages/FinaliseCampaignPage'), 3),
);
const UnsubscribeFromReferralPage = lazy(() =>
  componentLoader(() => import('../pages/UnsubscribeFromReferralPage'), 3),
);
const RescheduleAppointmentPage = lazy(() =>
  componentLoader(() => import('../pages/AppointmentPage/Reschedule'), 3),
);

const getRoutes = (initialRouteProps) => {
  const { config } = initialRouteProps;
  const { init, ...routeProps } = initialRouteProps;

  const taxID = config?.tax_id;

  const apiKey = init.api_key;
  const username = init.username;
  const autologin = init.role === 'autologin';
  const currency = getSymbolFromCurrency(config.currency);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path={'/'} element={<HomePage {...routeProps} />} />

        {/* Content pages */}
        <Route path={'/full-fibre'} element={<FullFibrePage {...routeProps} />} />
        <Route path={'/residential'} element={<ResidentialPage {...routeProps} />} />
        <Route path={'/business'} element={<BusinessPage {...routeProps} />} />
        <Route path={'/offers'} element={<OffersPage {...routeProps} />} />
        <Route path={'/about-us'} element={<AboutUsPage {...routeProps} />} />
        <Route path={'/news'} element={<NewsPage {...routeProps} />} />
        <Route path={'/news-article'} element={<NewsPostPage {...routeProps} />} />
        <Route path={'/faq'} element={<FaqPage {...routeProps} contentData={faqContent} />} />

        {/* Order related pages */}
        <Route path={'/coverage'} element={<CoveragePage {...routeProps} />} />
        <Route path={'/appointment'} element={<AppointmentPage {...routeProps} />} />
        <Route
          path={'/reschedule-appointment'}
          element={<RescheduleAppointmentPage {...routeProps} />}
        />

        {/* Customer pages */}
        <Route path={'/login'} element={<LoginPage {...routeProps} />} />
        <Route path={'/my-places'} element={<PersonalAreaPage {...routeProps} />} />
        <Route path={'/services'} element={<ServiceCatalogPage {...routeProps} />} />
        <Route path={'/payments'} element={<MyPaymentsPage {...routeProps} />} />
        <Route path={'/speed-test'} element={<SpeedTestPage {...routeProps} />} />
        <Route path={'/contact-us'} element={<ContactUsPageWithZammad {...routeProps} />} />
        <Route path={'/refer-friends'} element={<ReferralPage {...routeProps} />} />
        <Route path={'/customer_password'} element={<PasswordResetPage {...routeProps} />} />
        <Route path={'/finalise-campaign'} element={<FinaliseCampaignPage {...routeProps} />} />
        <Route
          path={'/my-services'}
          element={
            <CustomerServicesPage
              {...routeProps}
              apiKey={apiKey}
              username={username}
              currency={currency}
              autologin={autologin}
            />
          }
        />
        <Route
          path={'/profile'}
          element={
            <ProfilePage {...routeProps} apiKey={apiKey} autologin={autologin} taxID={taxID} />
          }
        />
        <Route
          path={'/service-status'}
          element={<ServiceStatusPage {...routeProps} username={username} />}
        />
        <Route
          path={'/unsubscribe-from-referral'}
          element={<UnsubscribeFromReferralPage {...routeProps} />}
        />
        <Route
          path={'/email_verification'}
          element={
            <AccountVerificationPage
              {...routeProps}
              processDefinitionKey="EmailVerificationProcess"
            />
          }
        />
        <Route
          path={'/request-password-reset'}
          element={<RequestPasswordResetPage {...routeProps} />}
        />
        <Route
          path={'/account_verification'}
          element={<AccountVerificationPageNew {...routeProps} />}
        />

        {/* T&C and policy related pages */}
        <Route path={'/cookie-policy'} element={<Navigate to="/legal?id=cookie-policy" />} />
        <Route path={'/legal/:id?'} element={<LegalPage {...routeProps} />} />

        {/* Placeholder pages */}
        <Route path={'/unauthorized'} element={<UnauthorizedPage {...routeProps} />} />
        <Route path="*" element={<NotFoundPage {...routeProps} />} />
      </Routes>
    </Suspense>
  );
};

export default getRoutes;
