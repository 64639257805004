export const leftLinks = (baseURL, isLoggedIn) => {
  const links = [
    {
      label: 'Full fibre',
      URL: `${baseURL}full-fibre`,
    },
    {
      label: 'Residential fibre',
      URL: `${baseURL}residential`,
    },
    {
      label: 'Business fibre',
      URL: `${baseURL}business`,
    },
    {
      label: 'Check my availability',
      URL: `${baseURL}coverage`,
    },
    isLoggedIn && {
      label: 'Service Status',
      URL: `${baseURL}service-status`,
    },
  ];

  return links.filter((link) => link);
};
export const rightLinks = (baseURL) => [
  {
    label: 'FAQs',
    URL: `${baseURL}faq`,
  },
  {
    label: 'Legal',
    URL: `${baseURL}legal`,
  },
  {
    label: 'Contact us',
    URL: `${baseURL}contact-us`,
  },
  {
    label: 'About us',
    URL: `${baseURL}about-us`,
  },
];
export const socialLinks = [
  {
    label: 'instagram',
    URL: 'https://www.instagram.com/lilaconnectuk/?hl=en-gb',
  },
  {
    label: 'facebook',
    URL: 'https://www.facebook.com/LilaConnectUK/',
  },
  {
    label: 'twitter',
    URL: 'https://twitter.com/LilaConnectUK',
  },
  {
    label: 'linkedin',
    URL: 'https://www.linkedin.com/company/lilaconnectuk/?viewAsMember=true',
  },
];
