import React, { useEffect } from 'react';

const pageTitles = {
  '/': 'Home',
  '/contact-us': 'Contact Us',
  '/faq': 'FAQ',
  '/services': 'Service Catalogue',
  '/coverage': 'Check My Availability',
  '/speed-test': 'Speed Test',
  '/my-services': 'My Services',
  '/my-places': 'My Places',
  '/payments': 'My Payments',
  '/installer': 'Installer Area',
  '/login': 'Login',
  '/signup': 'Signup',
  '/email_verification': 'Email Verification',
  '/profile': 'My Profile',
  '/customer_password': 'Reset Password',
  '/request-password-reset': 'Reset Password Request',
  '/unauthorized': 'Unauthorized',
  '/cancel_order': 'Cancel Order',
  '/about-us': 'About Us',
  '/appointment': 'Schedule Appointment',
  '/reschedule-appointment': 'Reschedule Appointment',
  '/residential': 'Residential',
  '/business': 'Business',
  '/offers': 'Offers',
  '/full-fibre': 'Full Fibre',
  '/finalise-campaign': 'Finalise Campaign',
  '/legal': 'Legal',
  '/legal?id=cookie-policy': 'Cookie Policy',
  '/legal?id=privacy-policy': 'Privacy Policy',
  '/legal?id=complaints-code': 'Complaints Code',
  '/legal?id=terms-and-conditions': 'Terms and Conditions',
  '/legal?id=acceptable-use-policy': 'Acceptable Use Policy',
  '/legal?id=terms-and-conditions-business': 'Terms and Conditions Business',
  '/legal?id=terms-and-conditions-residential': 'Terms and Conditions Residential',
};

// pageview EVENT
export const trackPageView = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.href,
        path: window.location.pathname,
        title: pageTitles[window.location.pathname],
      },
    });
  }
};

// generic EVENT
const trackEvent = (event, eventData) => {
  if (window.dataLayer) {
    window.dataLayer.push({ event, eventData });
  }
};

// ENQUIRY FUNNEL
//
// enquiryFunnelButton EVENT
export const trackEnquiryFunnelButton = (formStep) => {
  const eventData = {
    formId: 'ServiceEnquiryForm',
    formStep,
    referringService: sessionStorage.getItem('referringService'),
  };
  trackEvent('enquiryFunnelButton', eventData);
};

// FunnelStep EVENT
export const trackEnquiryFunnelStep = (formStep) => {
  const eventData = {
    formId: 'ServiceEnquiryForm',
    formStep,
    referringService: sessionStorage.getItem('referringService'),
  };
  trackEvent('FunnelStep', eventData);
};

// FunnelStep EVENT
export const trackEnquiryFormSubmit = (enquiryId, formStep) => {
  const eventData = {
    formId: 'ServiceEnquiryForm',
    formStep,
    enquiryId,
    referringService: sessionStorage.getItem('referringService'),
  };
  trackEvent('formSubmit', eventData);
};

// ORDERING FUNNEL
//
// enquiryFunnelButton EVENT
export const trackOrderingFunnelButton = (formStep) => {
  const eventData = {
    formId: 'ServiceOrderingForm',
    formStep,
    referringService: sessionStorage.getItem('referringService'),
  };
  trackEvent('orderFunnelButton', eventData);
};

// FunnelStep EVENT
export const trackOrderingFunnelStep = (formStep) => {
  const eventData = {
    formId: 'ServiceOrderingForm',
    formStep,
    referringService: sessionStorage.getItem('referringService'),
  };
  trackEvent('FunnelStep', eventData);
};

// FunnelStep EVENT
export const trackOrderingFormSubmit = (orderId, serviceOrdered, formStep) => {
  const eventData = {
    formId: 'ServiceOrderingForm',
    formStep,
    orderId,
    serviceOrdered,
    referringService: sessionStorage.getItem('referringService'),
  };
  trackEvent('formSubmit', eventData);
};

// Store the referring service that is the page where a funnel started
export const storeReferringService = (pathname) => {
  sessionStorage.setItem('referringService', pageTitles[pathname]);
};

export const WithStoreReferringService = (Component) => {
  const NewComponent = (props) => {
    useEffect(() => {
      // Store referring service when leaving the page
      return () => storeReferringService(props.location.pathname);
    }, []);

    return <Component {...props} />;
  };

  return NewComponent;
};
