import React from 'react';
import { Image, Segment, Divider } from 'semantic-ui-react';

import './style.css';

export default function BrowserMessage() {
  return (
    <div className="browser-message">
      <Segment basic textAlign="center">
        <Image src="/images/logo-dark.svg" size="small" centered />

        <Divider hidden />

        <h4>Oops. Looks like you are using an old browser.</h4>

        <p>
          LilaConnect will take you to the future, but you will need to use an updated browser.
          <br />
          Please download a more recent browser to see what the future looks like.
        </p>
      </Segment>
    </div>
  );
}
