import { SET_OFFER_CUSTOMER_TYPE } from '../actions/offerCustomerType.actions';

const InitCustomerType = 'residential';

export default function offerCustomerType(state = InitCustomerType, { type, data }) {
  switch (type) {
    case SET_OFFER_CUSTOMER_TYPE:
      return data;
    default:
      return state;
  }
}
